module controllers {
    export module customs {

        interface ICustomsRiskManagement extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        interface ICustomsRiskManagementParams extends ng.ui.IStateParamsService {
        }


        export class customsRiskManagementCtrl {

            static $inject = [
                "$scope",
                "$stateParams",
                "$rootScope",
                "customsService",
                "classificationValueService",
                "$timeout",
                "generalService",
                "$q",
                "$state",
                "entityService",
                "goodsDeclarationService",
                'countryService',
                'refundClaimTypeService',
                "statusService",
                "$uibModal",
                "tariffCountryService",
                "bsLoadingOverlayService",
                'menuService'
                
            ];

            showGrid: boolean = false;
            IsLoading: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            searchAccordian: boolean = false;

            showAll: boolean = true;
            selectedDeclarationCountry: interfaces.applicationcore.IDropdownModel;
            entityId: number;
            tariffCountries: interfaces.applicationcore.IDropdownModel[];
            selectedCustomsRiskManagementGoodsDeclarationViewModel:Array<interfaces.customs.CustomsRiskManagementGoodsDeclarationViewModel> = [];
            gridApi: uiGrid.IGridApi;
            sortName: string;
            sortDirection: string;
            selectedLine: uiGrid.IGridRow;

            hasReleaseCreditLimitRight: boolean = false;
            hasAddCustomerPaymentRight: boolean = false;
            hasExtendExpiryDateRight: boolean = false;
            hasManuallyCloseDeclarationRight: boolean = false; 
            viewConsignmentRight: boolean = false;  

            constructor(
                private $scope: ICustomsRiskManagement,
                private $stateParams: ICustomsRiskManagementParams,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private customsService: interfaces.customs.ICustomsService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,
                private countryService: interfaces.applicationcore.ICountryService,
                private refundClaimTypeService: services.tariff.refundClaimTypeService,
                private statusService: interfaces.master.IStatusService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private tariffCountryService: interfaces.tariff.ITariffCountryService,
                public bsLoadingOverlayService,
                private menuService: interfaces.applicationcore.IMenuService
            ) {
                    
                this.getCurrentEntity().then(() => {   
                    this.loadCountries().then((result: interfaces.applicationcore.IDropdownModel[]) => {
                        this.tariffCountries = result;
                        this.getCurrentCountry().then(() => {   

                            this.GetCustomsRiskManagementGoodsDeclarationViewModelData();

                            var loadPromises: ng.IPromise<any>[] = [];
                                                                                                                        
                            loadPromises.push(this.getViewImportConsignmentRight());
                            loadPromises.push(this.getHasReleaseCreditLimitRight());
                            loadPromises.push(this.gethasAddCustomerPaymentRight());
                            loadPromises.push(this.getHasExtendExpiryDateRight());
                            loadPromises.push(this.getHasManuallyCloseDeclarationRight());

                            return this.$q.all(loadPromises);
                        });
                         
                    });
                });

                $timeout(() => {
                    this.showGrid = true;
                });
            }

            getCurrentEntity(): ng.IPromise<boolean> {

                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.entityId = result.Id;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadCountries() {
                return this.tariffCountryService.getDropdownListByTransactionEntity(this.entityId).query().$promise;
            }

            getCurrentCountry()
            {
                return this.entityService.getEntityCountry().get({
                    entityId: this.entityId
                }, (result: interfaces.applicationcore.ICountry) => {
                    this.selectedDeclarationCountry = this.tariffCountries.find((country) => { return country.Id == result.Id });
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            downloadToExcel(){

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                if (this.gridApi) {
                    var grid = this.gridApi.grid;
                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    declarationCountryId : this.selectedDeclarationCountry.Id,
                    showAll : this.showAll,
                }

                this.IsLoading = true;
                this.goodsDeclarationService.getCustomsRiskManagementGoodsDeclarationViewModelExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;              
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            GetCustomsRiskManagementGoodsDeclarationViewModelData(){

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                if (this.gridApi) {
                    var grid = this.gridApi.grid;
                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

              

                var params = {
                    connectSearch: searchObject,
                    declarationCountryId : this.selectedDeclarationCountry.Id,
                    showAll : this.showAll,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                }
                this.IsLoading = true;
                return this.goodsDeclarationService.getCustomsRiskManagementGoodsDeclarationViewModel().query(
                    params, (result: Array<interfaces.customs.CustomsRiskManagementGoodsDeclarationViewModel>) => {
                        this.IsLoading = false;
                        this.gvwGoodsDeclarations.data = result;
                        this.searchAccordian = false;

                        if (result[0]) {
                            this.gvwGoodsDeclarations.totalItems = result[0].numRecords;
                        } else {
                            this.gvwGoodsDeclarations.totalItems = 0;
                        }
                        
                        this.gridApi.selection.clearSelectedRows();
                        for (let item of this.gvwGoodsDeclarations.data) {
                            var foundItem = _.find(this.selectedCustomsRiskManagementGoodsDeclarationViewModel, item as interfaces.customs.CustomsRiskManagementGoodsDeclarationViewModel);
                            if (foundItem) {

                                this.$timeout(() => {
                                    this.gridApi.selection.unSelectRow(item);
                                    this.gridApi.selection.selectRow(item);
                                });
                            }
                        }
                   
                }, (errorResponse) => {
                    this.IsLoading = false;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            sortChange(gridApi) {
                this.GetCustomsRiskManagementGoodsDeclarationViewModelData();
            }
            
            filterChange(gridApi: uiGrid.IGridApi) {
                this.GetCustomsRiskManagementGoodsDeclarationViewModelData();
            }

            
            ListPageChange(newPage, pageSize) {

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.GetCustomsRiskManagementGoodsDeclarationViewModelData();
            }


            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.filterChange(gridApi) });
                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.sortChange(gridApi);
                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });


                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });


                //ROW SELECTION

                this.gridApi.selection.on.rowSelectionChangedBatch(this.$scope,(rows)=>{
                    
                    for (let selected of rows){
                        var foundItem = _.find(this.selectedCustomsRiskManagementGoodsDeclarationViewModel, selected.entity);

                        if(!foundItem) {
                            if (selected.isSelected){
                                this.selectedCustomsRiskManagementGoodsDeclarationViewModel = _.concat(this.selectedCustomsRiskManagementGoodsDeclarationViewModel, selected.entity);  
                            } 
                        }
                        else {
                            _.remove(this.selectedCustomsRiskManagementGoodsDeclarationViewModel, selected.entity);
                        }
                    }

 

                    if (this.gridApi.selection.getSelectedRows().length === 0) {
                                                          
                    }
                    else{
                        
                    }
                });


                this.gridApi.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    
                    this.selectedLine = selected;  
                    var foundItem = _.find(this.selectedCustomsRiskManagementGoodsDeclarationViewModel, selected.entity);

                    if(!foundItem) {
                        if (selected.isSelected){
                            this.selectedCustomsRiskManagementGoodsDeclarationViewModel = _.concat(this.selectedCustomsRiskManagementGoodsDeclarationViewModel, selected.entity);  
                        } 
                    }
                    else {
                        _.remove(this.selectedCustomsRiskManagementGoodsDeclarationViewModel, selected.entity);
                    }

                    

                    if (this.gridApi.selection.getSelectedRows().length === 0) {
                                                  
                    }
                    else{
                       
                    }

                });


            }

            public gvwGoodsDeclarations: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                enableRowHeaderSelection: true,                            
                showGridFooter: true,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },                
                columnDefs: [ 
                            {
                                name: "Status",
                                field: "Status",
                                displayName: "",
                                enableFiltering: false,
                                cellTemplate: `<div style="text-align: center; padding-top: 6px;">
                                                <div ng-if="row.entity.StatusIndicator === 0">
                                                    <span class="fa fa-edit"></span>
                                                </div>
                                                <div ng-if="row.entity.StatusIndicator === 1">
                                                    <span class="fa fa-times" style="color: #cb2330;"></span>
                                                </div>
                                                <div ng-if="row.entity.StatusIndicator === 2">
                                                    <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                                                </div>
                                                <div ng-if="row.entity.StatusIndicator === 3">
                                                    <span class="fa fa-check-circle" style="color: #bcd856;"></span>
                                                </div>
                                                </div>`,
                                enableSorting: false,
                                enableColumnMenu: false,
                                width: 35
                            },                               
                            {
                                name: "EntityCode",
                                displayName: "Entity",
                                field: "EntityCode",
                                width: 60,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "LRN",
                                displayName: "LRN",
                                field: "LRN",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                     <a style="cursor:pointer" ng-click="grid.appScope.customsRiskManagementCtrl.viewGoodsDeclaration(row.entity.ConsignmentId, row.entity.IsInbound, row.entity.DeclarationId, row.entity.DeclarationVersion)"><u>{{MODEL_COL_FIELD}}</u></a>
                                    </div>`,
                            },      
                            {
                                name: "MRN",
                                displayName: "MRN / Assessment",
                                field: "MRN",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "AssessmentDate",
                                displayName: "Assessment Date",
                                field: "AssessmentDate",
                                cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                                width: 120,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                                        </div>`,
                            },   
                            {
                                name: "STATUS",
                                displayName: "Status",
                                field: "Status",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            }, 
                            {
                                name: "ExpiryDate",
                                displayName: "Expiry Date",
                                field: "ExpiryDate",
                                cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                                width: 120,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                                        </div>`,
                            }, 
                            {
                                name: "CPC",
                                displayName: "CPC",
                                field: "CPC",
                                width: 130,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "ClientName",
                                displayName: "Client",
                                field: "ClientName",
                                width: 160,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "Declarant",
                                displayName: "Declarant",
                                field: "Declarant",
                                width: 160,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },                                                                                            
                            {
                                name: "Consignment",
                                displayName: "Consignment",
                                field: "Consignment",
                                width: 140,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                        <a style="cursor:pointer"  ng-click="grid.appScope.customsRiskManagementCtrl.viewConsignment(row.entity.ConsignmentId, row.entity.IsInbound)"><u>{{MODEL_COL_FIELD}}</u></a>
                                       </div>`
                            },
                            {
                                name: "TotalCustomsValue",
                                displayName: "Total Customs Value",
                                field: "TotalCustomsValue",
                                width: 120,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },   
                            {
                                name: "TotalDutiesBond",
                                displayName: "Total Duties / Bond",
                                field: "TotalDutiesBond",
                                width: 120,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },                       
                            {
                                name: "Currency",
                                displayName: "Currency",
                                field: "CurrencyCode",
                                width: 60,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "CreditHold",
                                displayName: "Credit Hold",
                                field: "CreditHold",
                                width: 100,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.CreditHold ? 'Yes' : 'No'}}">{{row.entity.CreditHold ? 'Yes' : 'No'}}</div>`
                            },
                            {
                                name: "Closed",
                                displayName: "Closed",
                                field: "Closed",
                                width: 100,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.Closed ? 'Yes' : 'No'}}">{{row.entity.Closed ? 'Yes' : 'No'}}</div>`
                            }                                               
                ]                
            }


            getViewImportConsignmentRight() {
                this.viewConsignmentRight = false;
                return this.menuService.getGTSConnectMenuItem(379).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.viewConsignmentRight = true;
                    }
                }).$promise;
            }    

            getHasReleaseCreditLimitRight() {
                this.hasReleaseCreditLimitRight = false;
                return this.menuService.getGTSConnectMenuItem(716).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.hasReleaseCreditLimitRight = true;
                    }
                }).$promise;
            }    

            gethasAddCustomerPaymentRight() {
                this.hasAddCustomerPaymentRight = false;
                return this.menuService.getGTSConnectMenuItem(717).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.hasAddCustomerPaymentRight = true;
                    }
                }).$promise;
            } 
            
            getHasExtendExpiryDateRight() {
                this.hasExtendExpiryDateRight = false;
                return this.menuService.getGTSConnectMenuItem(712).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.hasExtendExpiryDateRight = true;
                    }
                }).$promise;
            } 
            
            getHasManuallyCloseDeclarationRight() {
                this.hasManuallyCloseDeclarationRight = false;
                return this.menuService.getGTSConnectMenuItem(713).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.hasManuallyCloseDeclarationRight = true;
                    }
                }).$promise;
            }                     
            


            viewConsignment(consignmentId: number, isInbound: boolean) {

                var url = "";
                if (isInbound){
                    url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
                }
                else{                    
                    url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
                }
        
                window.open(url, '_blank');
            }

            viewGoodsDeclaration(consignmentId: number, isInbound: boolean, goodsDeclarationId: number, goodsDeclarationVersion: number) {

                var url = "";
                if (isInbound){
                    url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
                }
                else{                    
                    url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
                }

                url = url + "/GoodsDeclaration/" + goodsDeclarationId + "/Version/" + goodsDeclarationVersion;
        
                window.open(url, '_blank');
            }

            AddCustomerPayment(){
                 
                this.$uibModal.open({
                    animation: true,
                    size: "xlg",
                    template: `
                                    <gts-account-payment customs-party-id="AccountPaymentModalCtrl.customsPartyId" 
                                                        account-payment-id="AccountPaymentModalCtrl.accountPaymentId"
                                                        close="AccountPaymentModalCtrl.ok()"
                                    >
                                    </gts-account-payment>                            
                                `,
                    controller: class diDetailCostingCtrl {
                        customsPartyId: number;
                        accountPaymentId: number;

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, private items, private bsLoadingOverlayService,
                            private deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService, private generalService) {
                            this.customsPartyId = items.customsPartyId;
                            this.accountPaymentId = items.accountPaymentId;

                        }

                        ok() {
                            this.$uibModalInstance.close();
                        }

                        cancel() {
                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: 'AccountPaymentModalCtrl',
                    resolve: {
                        items: () => {
                            return {
                                customsPartyId: null,
                                accountPaymentId: null
                            };
                        }
                    }
                }).result.then((result) => {
                    
                    //this.generalService.displayMessage("Customer Payment added successfully.", Enum.EnumMessageType.Success);
                    
                });
           
        }
            ExtendExpiryDates(){

                    //Atleast 1 line must be seleced otherwise display error
                    if (this.selectedCustomsRiskManagementGoodsDeclarationViewModel.length === 0) {
                        this.generalService.displayMessage("At least 1 line must be selected.", Enum.EnumMessageType.Error);
                        return;
                    }



                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Update Expiry Date</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="gd.modal">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="modalPopupCtrl.messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <req></req>
						                            <label>Expiry Date</label>
					                            </div>
					                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd" ng-model="modalPopupCtrl.expiryDate" name="expiryDate"                                                    
                                                ></gts-date>
					                            </div>
				                            </div>                                            
			                            </div>                                          
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="!modalPopupCtrl.expiryDate" ng-click="modalPopupCtrl.Submit()">Submit</button>
                                        <button class="btn btn-info" type="button"  ng-click="modalPopupCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class modalPopupCtrl {

                        messages: interfaces.applicationcore.IMessage[] = [];
                        disableSubmit: boolean = false;
                        receiptNumber:string;
                        expiryDate: moment.Moment;
                      
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public bsLoadingOverlayService,
                            public ENV: interfaces.applicationcore.serverConfig,
                        ) {                         
                        }
                 
                        Submit(){
                            this.$uibModalInstance.close(this.expiryDate);                                               
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }
 
 
                    },
                    controllerAs: "modalPopupCtrl",
                    resolve: { 
                    }
                }).result.then((result) => {
                    if (result) {
                        

                        const goodsDeclarationsDetail: Array<interfaces.customs.GoodsDeclarationDetail> = this.selectedCustomsRiskManagementGoodsDeclarationViewModel.map(
                            (item) => {
                              return {
                                goodsDeclarationId: item.DeclarationId,
                                goodsDeclarationVersion: item.DeclarationVersion,
                                ediStatusId: null,
                                comments: null,
                                expiryDate: result
                              };
                            });
        
                            console.log(goodsDeclarationsDetail);
                            const goodsDeclarationToAction: interfaces.customs.GoodsDeclarationsToAction = { 
                                goodsDeclarations: goodsDeclarationsDetail,                    
                            }
        
        
                        return this.bsLoadingOverlayService.wrap({
                                        referenceId: 'cra.gd'
                                    }, () => {
                                  
                                    return this.goodsDeclarationService.updateExpiryDates().save({}, goodsDeclarationToAction,
                                        (result: interfaces.applicationcore.IMessageHandler) => {
                                            this.generalService.displayMessageHandler(result);
                    
                                            if (!result.HasErrorMessage) {
                                                
                                                this.GetCustomsRiskManagementGoodsDeclarationViewModelData();   
        
                                            }
                                        }).$promise;
                                });

                    }
                });
            }

            MnaullyCloseDeclaration(){

                                    //Atleast 1 line must be seleced otherwise display error
                                    if (this.selectedCustomsRiskManagementGoodsDeclarationViewModel.length === 0) {
                                        this.generalService.displayMessage("At least 1 line must be selected.", Enum.EnumMessageType.Error);
                                        return;
                                    }

                                    
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Manually Close Goods Declaration</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="gd.modal">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="modalPopupCtrl.messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
                                            <div class="row vertical-align" ng-if="modalPopupCtrl.declarationCountryId !== 249"> 
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    EDI Status
                                                </div>
                                                <div class="col-md-6"  >
                                                    <gts-dropdown name="customstatus" ng-model="modalPopupCtrl.ediStatus" form="GoodsDecDetail" load-data="modalPopupCtrl.loadCustomsStatus()" required="false"></gts-dropdown>
                                                </div>
                                            </div>

				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <req></req>
						                            <label>Comment</label>
					                            </div>
					                            <div class="col-md-6">
                                                <p class="input-group-sm has-feedback" >
                                                    <textarea rows="3" name="Reason" ng-model="modalPopupCtrl.comments" class="form-control"></textarea>
                                                </p>
					                            </div>
				                            </div>                                            
			                            </div>                                          
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"   ng-click="modalPopupCtrl.Submit()">Submit</button>
                                        <button class="btn btn-info" type="button"  ng-click="modalPopupCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class modalPopupCtrl {

                        messages: interfaces.applicationcore.IMessage[] = [];
                        disableSubmit: boolean = false;
                        receiptNumber:string;
                        comments: string;
                        ediStatusId?: number;
                        ediStatus: interfaces.applicationcore.IDropdownModel;
                      
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private goodsDeclarationService: interfaces.customs.IGoodsDeclarationService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public bsLoadingOverlayService,
                            public ENV: interfaces.applicationcore.serverConfig,
                            public ediCusresStatusService: interfaces.integration.IEDICusresStatusService,
                            private $q: ng.IQService,
                            private declarationCountryId:number
                        ) {

                         
                        }

                        loadCustomsStatus(){
                            var deferred = this.$q.defer();
 
                                this.ediCusresStatusService.getForDropDownByCountry(this.declarationCountryId).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                                    deferred.resolve(list);
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    deferred.resolve([]);
                                });

                             return deferred.promise;
                        }
                 
                        Submit(){
                            const closeGoodsDeclaration: interfaces.customs.CloseGoodsDeclaration = {
                                comments : this.comments,
                                ediStatusId: this.ediStatus? this.ediStatus.Id : null
                            }
                            this.$uibModalInstance.close(closeGoodsDeclaration);                            
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }
 
 
                    },
                    controllerAs: "modalPopupCtrl",
                    resolve: {  declarationCountryId : this.selectedDeclarationCountry.Id
                    }
                }).result.then((result:  interfaces.customs.CloseGoodsDeclaration) => {
                    if (result) {

                        const goodsDeclarationsDetail: Array<interfaces.customs.GoodsDeclarationDetail> = this.selectedCustomsRiskManagementGoodsDeclarationViewModel.map(
                            (item) => {
                              return {
                                goodsDeclarationId: item.DeclarationId,
                                goodsDeclarationVersion: item.DeclarationVersion,
                                ediStatusId: result.ediStatusId,
                                comments: result.comments,
                                expiryDate: null
                              };
                            });
        
                            console.log(goodsDeclarationsDetail);
                            const goodsDeclarationToAction: interfaces.customs.GoodsDeclarationsToAction = { 
                                goodsDeclarations: goodsDeclarationsDetail,                    
                            }
        
        
                        return this.bsLoadingOverlayService.wrap({
                                        referenceId: 'cra.gd'
                                    }, () => {
                                  
                                    return this.goodsDeclarationService.manuallyCloseGoodsDeclarations().save({}, goodsDeclarationToAction,
                                        (result: interfaces.applicationcore.IMessageHandler) => {
                                            this.generalService.displayMessageHandler(result);
                    
                                            if (!result.HasErrorMessage) {
                                                
                                                this.GetCustomsRiskManagementGoodsDeclarationViewModelData();   
        
                                            }
                                        }).$promise;
                                });

                    }
                });
            }
 

            ReleaseCreditLimit() { 

                //Atleast 1 line must be seleced otherwise display error
                if (this.selectedCustomsRiskManagementGoodsDeclarationViewModel.length === 0) {
                    this.generalService.displayMessage("At least 1 line must be selected.", Enum.EnumMessageType.Error);
                    return;
                }

                //make sure all selected items are credit hold and return error if now
                var allCreditHold = true;
                for (let item of this.selectedCustomsRiskManagementGoodsDeclarationViewModel) {
                    if (!item.CreditHold) {
                        allCreditHold = false;
                        break;
                    }
                }

                if (!allCreditHold) {
                    this.generalService.displayMessage("All selected items must be currently on a Credit Hold to release the Credit Hold.", Enum.EnumMessageType.Error);
                    return;
                }

                const goodsDeclarationsDetail: Array<interfaces.customs.GoodsDeclarationDetail> = this.selectedCustomsRiskManagementGoodsDeclarationViewModel.map(
                    (item) => {
                      return {
                        goodsDeclarationId: item.DeclarationId,
                        goodsDeclarationVersion: item.DeclarationVersion,
                        ediStatusId: null,
                        comments: null,
                        expiryDate: null
                      };
                    });

                    const goodsDeclarationToAction: interfaces.customs.GoodsDeclarationsToAction = { 
                        goodsDeclarations: goodsDeclarationsDetail,                    
                    }


                return this.bsLoadingOverlayService.wrap({
                                referenceId: 'cra.gd'
                            }, () => {
                          
                            return this.goodsDeclarationService.releaseCreditHold().save({}, goodsDeclarationToAction,
                                (result: interfaces.applicationcore.IMessageHandler) => {
                                    this.generalService.displayMessageHandler(result);
            
                                    if (!result.HasErrorMessage) {
                                        
                                        this.GetCustomsRiskManagementGoodsDeclarationViewModelData();   

                                    }
                                }).$promise;
                        });
            }




        }
        angular.module("app").controller("customsRiskManagementCtrl", controllers.customs.customsRiskManagementCtrl);
    }
}